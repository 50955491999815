import * as React from 'react'
import { ErrorBoundary, ErrorBoundaryProps, withErrorBoundary } from 'react-error-boundary'
import { ReactNode, useEffect, useRef } from 'react'
import { RenderError } from 'components/molecules/RenderError'
import { v4 as uuidv4 } from 'uuid'
import Bugsnag from '@bugsnag/js'
import NewModal from 'components/molecules/Modal'

const ErrorBoundaryContainer: React.FC<
    Partial<
        ErrorBoundaryProps & {
            children: React.ReactNode
            stringMode?: boolean
            errorComponentProps?: any
            fallbackComponent?: ReactNode
        }
    >
> = ({ children, errorComponentProps, stringMode, fallbackComponent }) => {
    return (
        <ErrorBoundary FallbackComponent={Fallback(false, stringMode, errorComponentProps, fallbackComponent) as any}>
            {children}
        </ErrorBoundary>
    )
}

const Fallback =
    (isModal?: boolean, stringMode?: boolean, errorComponentProps?: any, fallbackComponent?: ReactNode) =>
    // eslint-disable-next-line react/display-name
    ({ error, resetErrorBoundary }: any) => {
        const errorTrackId = useRef<string>()

        useEffect(() => {
            errorTrackId.current = uuidv4()

            console.error(error)

            Bugsnag.notify(error, (event) => {
                event.addMetadata('additional', {
                    errorTrackId: errorTrackId.current,
                })
            })
        }, [])

        return isModal ? (
            <NewModal>
                {stringMode ? (
                    'INVALID'
                ) : (
                    <RenderError
                        {...errorComponentProps}
                        errorId={errorTrackId.current}
                        resetErrorBoundary={resetErrorBoundary}
                    />
                )}
            </NewModal>
        ) : stringMode ? (
            'INVALID'
        ) : (
            fallbackComponent || (
                <RenderError
                    {...errorComponentProps}
                    errorId={errorTrackId.current}
                    resetErrorBoundary={resetErrorBoundary}
                />
            )
        )
    }

export const withErrorBoundaryContainer = <T,>(children: React.ComponentType<T>) =>
    withErrorBoundary<T>(children, {
        FallbackComponent: Fallback(true) as any,
    })

export default ErrorBoundaryContainer
