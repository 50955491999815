import { IntlProvider } from 'react-intl'
import { LOCALE } from 'src/constants/locales'
import { useStore } from 'src/store'
import EN from '../../compiled-lang/en.json'
import React, { FC, ReactNode } from 'react'

interface Props {
    locale?: LOCALE
    children: ReactNode
}

const messages = {
    en: EN,
}

const LanguageProvider: FC<Props> = ({ children, ...rest }) => {
    const { locale = 'en' } = useStore().common

    return (
        <IntlProvider locale={locale} messages={messages[locale]} onError={() => {}} {...rest}>
            {children}
        </IntlProvider>
    )
}

export default LanguageProvider
