import * as React from 'react'
import { CardStore, CardStoreContext } from 'src/store/CardStore'
import { Outlet } from 'react-router-dom'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import ErrorBoundaryContainer from 'containers/ErrorBoundaryContainer'
import Footer from './Footer'
import Header from './Header'
import styled from 'astroturf/react'

const PrivateCardTemplate = () => {
    const { auth } = useStore()

    return (
        <CardStoreContext.Provider value={CardStore(auth.user?.login)}>
            <PrivateCardTemplateContent />
        </CardStoreContext.Provider>
    )
}

const PrivateCardTemplateContent: React.FC = () => {
    const windowWidth = useWindowSize().width

    return (
        <Container>
            <>
                <Content>
                    {windowWidth > 600 && <Header withLogo noVerifyButton />}
                    <PageContent id="page-content">
                        <PageContainer>
                            <ErrorBoundaryContainer>
                                <Outlet />
                            </ErrorBoundaryContainer>
                        </PageContainer>
                    </PageContent>
                </Content>
                <FooterStyled posStatic />
            </>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
`

const FooterStyled = styled(Footer)`
    padding: 24px 50px 48px !important;
    background: var(--bg-color-2);
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const PageContainer = styled.div`
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;
`

const PageContent = styled.div`
    box-sizing: border-box;
    flex: 1;
    overflow-scrolling: touch;
    padding: 38px 46px;
    background: var(--bg-color-2);

    @media (max-width: 1000px) {
        padding-right: 0;
        padding-left: 0;
    }

    @media (max-width: 600px) {
        padding-top: 0;
    }
`

export default PrivateCardTemplate
